//default
import React from "react";
//librerias
import { BrowserRouter as Router } from "react-router-dom";
//componentes
import Rutas from "../../rutas/rutas";
import Navbar from "../../components/navbar";
//css
import "./principal.scss";

const Principal = ({ setUser }) => {
	return (
		<div className="contenedor_principal">
			<Router>
				<Navbar setUser={setUser} />
				<Rutas />
			</Router>
		</div>
	);
};

export default Principal;
