//default
import React from "react";

export const Img1 = () => {
	return (
		<svg fill="none" height="24" viewBox="0 0 24 24" width="24">
			<g id="Add">
				<path
					clipRule="evenodd"
					d="M1.25 3C1.25 1.48122 2.48122 0.25 4 0.25H14C15.5188 0.25 16.75 1.48122 16.75 3V10.5C16.75 10.9142 16.4142 11.25 16 11.25C15.5858 11.25 15.25 10.9142 15.25 10.5V3C15.25 2.30964 14.6904 1.75 14 1.75H4C3.30964 1.75 2.75 2.30964 2.75 3V21C2.75 21.6904 3.30964 22.25 4 22.25H12C12.4142 22.25 12.75 22.5858 12.75 23C12.75 23.4142 12.4142 23.75 12 23.75H4C2.48122 23.75 1.25 22.5188 1.25 21V3Z"
					fill="black"
					fillRule="evenodd"
					id="Rectangle 12 (Stroke)"
				/>
				<path
					clipRule="evenodd"
					d="M4.36201 0.605702C4.49867 0.384589 4.74007 0.25 5 0.25H13C13.2599 0.25 13.5013 0.384589 13.638 0.605702C13.7746 0.826814 13.7871 1.10292 13.6708 1.33541L12.9472 2.78262C12.6508 3.3755 12.0448 3.75 11.382 3.75H6.61803C5.95518 3.75 5.34922 3.3755 5.05279 2.78262L4.32918 1.33541C4.21293 1.10292 4.22536 0.826814 4.36201 0.605702ZM6.21353 1.75L6.39443 2.1118C6.43678 2.1965 6.52334 2.25 6.61803 2.25H11.382C11.4767 2.25 11.5632 2.1965 11.6056 2.1118L11.7865 1.75H6.21353Z"
					fill="black"
					fillRule="evenodd"
					id="Rectangle 13 (Stroke)"
				/>
				<path
					clipRule="evenodd"
					d="M6.25 20C6.25 19.5858 6.58579 19.25 7 19.25H11C11.4142 19.25 11.75 19.5858 11.75 20C11.75 20.4142 11.4142 20.75 11 20.75H7C6.58579 20.75 6.25 20.4142 6.25 20Z"
					fill="black"
					fillRule="evenodd"
					id="Vector 11 (Stroke)"
				/>
				<path
					clipRule="evenodd"
					d="M18 13.75C15.6528 13.75 13.75 15.6528 13.75 18C13.75 20.3472 15.6528 22.25 18 22.25C20.3472 22.25 22.25 20.3472 22.25 18C22.25 15.6528 20.3472 13.75 18 13.75ZM12.25 18C12.25 14.8244 14.8244 12.25 18 12.25C21.1756 12.25 23.75 14.8244 23.75 18C23.75 21.1756 21.1756 23.75 18 23.75C14.8244 23.75 12.25 21.1756 12.25 18Z"
					fill="black"
					fillRule="evenodd"
					id="Ellipse 7 (Stroke)"
				/>
				<path
					clipRule="evenodd"
					d="M18 15.25C18.4142 15.25 18.75 15.5858 18.75 16V20C18.75 20.4142 18.4142 20.75 18 20.75C17.5858 20.75 17.25 20.4142 17.25 20V16C17.25 15.5858 17.5858 15.25 18 15.25Z"
					fill="black"
					fillRule="evenodd"
					id="Vector 14 (Stroke)"
				/>
				<path
					clipRule="evenodd"
					d="M15.25 18C15.25 17.5858 15.5858 17.25 16 17.25H20C20.4142 17.25 20.75 17.5858 20.75 18C20.75 18.4142 20.4142 18.75 20 18.75H16C15.5858 18.75 15.25 18.4142 15.25 18Z"
					fill="black"
					fillRule="evenodd"
					id="Vector 15 (Stroke)"
				/>
			</g>
		</svg>
	);
};

export const Img2 = () => {
	return (
		<svg enableBackground="new 0 0 30 30" height="30px" id="Capa_1" version="1.1" viewBox="0 0 30 30" width="30px">
			<path
				d="M3.8,4.5C3.4,4.5,3,4.8,3,5.3c0,0.5,0.4,0.8,0.8,0.8c0.5,0,0.8-0.4,0.8-0.8C4.7,4.8,4.3,4.5,3.8,4.5z   M27.2,1.6H2.8c-1.5,0-2.7,1.2-2.7,2.7v21.3c0,1.5,1.2,2.7,2.7,2.7h24.5c1.5,0,2.7-1.2,2.7-2.7V4.3C29.9,2.9,28.7,1.6,27.2,1.6z   M28.4,25.7c0,0.7-0.5,1.2-1.2,1.2H2.8c-0.7,0-1.2-0.5-1.2-1.2v-4.1c0.2,0.2,0.5,0.4,0.7,0.7c0.4,0.4,0.8,0.9,1.4,1.2  c0.2,0.9,1,1.6,2,1.6c1,0,1.8-0.7,2-1.7c1.3-1,2.1-2.7,2.8-4.4c0.5-1.3,1.1-2.6,1.8-3.2c0.4,0.6,1,1,1.7,1c0.7,0,1.4-0.4,1.7-1  c0.4,0.3,0.8,0.7,1.2,1c0.6,0.6,1.3,1.2,2.1,1.6c0.3,0.7,1,1.3,1.9,1.3c1,0,1.8-0.7,2-1.6c1.2-0.8,1.9-2.2,2.7-3.6  c1-1.9,1.7-3.1,3-3.4V25.7z M5.1,23c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5c0,0.3-0.2,0.5-0.5,0.5C5.4,23.5,5.1,23.3,5.1,23z   M13.4,14.7c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5c0,0.3-0.2,0.5-0.5,0.5C13.7,15.2,13.4,14.9,13.4,14.7z M20.4,17.5  c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5S21.1,18,20.9,18S20.4,17.8,20.4,17.5z M28.4,9.4c-2.3,0.3-3.3,2.4-4.3,4.3  c-0.5,1-1,2-1.7,2.7c-0.4-0.5-0.9-0.8-1.6-0.8c-0.8,0-1.4,0.4-1.8,1.1c-0.4-0.3-0.8-0.6-1.2-1c-0.6-0.6-1.3-1.2-2.1-1.6  c-0.3-0.8-1-1.4-1.9-1.4c-0.9,0-1.6,0.6-1.9,1.4c-1.4,0.6-2.2,2.4-3,4.3c-0.6,1.3-1.1,2.6-1.9,3.4C6.8,21.3,6.3,21,5.7,21  C5,21,4.4,21.4,4,21.8c-0.2-0.2-0.4-0.4-0.6-0.6c-0.5-0.6-1.1-1.1-1.8-1.3V9h26.9V9.4z M28.4,7.5H1.6V4.3c0-0.7,0.5-1.2,1.2-1.2  h24.5c0.7,0,1.2,0.5,1.2,1.2V7.5z M6.4,4.5C6,4.5,5.6,4.8,5.6,5.3c0,0.5,0.4,0.8,0.8,0.8c0.5,0,0.8-0.4,0.8-0.8  C7.2,4.8,6.9,4.5,6.4,4.5z M9,4.5c-0.5,0-0.8,0.4-0.8,0.8c0,0.5,0.4,0.8,0.8,0.8c0.5,0,0.8-0.4,0.8-0.8C9.8,4.8,9.4,4.5,9,4.5z"
				fill="#262324"
			/>
		</svg>
	);
};

export const Img3 = () => {
	return (
		<svg id="Flat" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
			<path
				fill="black"
				d="M220.27246,132.24219l-42,42a5.99971,5.99971,0,0,1-8.48535-8.48438L201.54443,134H104a6,6,0,0,1,0-12h97.54443L169.78711,90.24219a5.99971,5.99971,0,1,1,8.48535-8.48438l42,42A5.99959,5.99959,0,0,1,220.27246,132.24219ZM120,210H48a2.0026,2.0026,0,0,1-2-2V48a2.0026,2.0026,0,0,1,2-2h72a6,6,0,0,0,0-12H48A14.01572,14.01572,0,0,0,34,48V208a14.01572,14.01572,0,0,0,14,14h72a6,6,0,0,0,0-12Z"
			/>
		</svg>
	);
};

export const Img4 = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
			<path d="M160 0C177.7 0 192 14.33 192 32V67.68C193.6 67.89 195.1 68.12 196.7 68.35C207.3 69.93 238.9 75.02 251.9 78.31C268.1 82.65 279.4 100.1 275 117.2C270.7 134.3 253.3 144.7 236.1 140.4C226.8 137.1 198.5 133.3 187.3 131.7C155.2 126.9 127.7 129.3 108.8 136.5C90.52 143.5 82.93 153.4 80.92 164.5C78.98 175.2 80.45 181.3 82.21 185.1C84.1 189.1 87.79 193.6 95.14 198.5C111.4 209.2 136.2 216.4 168.4 225.1L171.2 225.9C199.6 233.6 234.4 243.1 260.2 260.2C274.3 269.6 287.6 282.3 295.8 299.9C304.1 317.7 305.9 337.7 302.1 358.1C295.1 397 268.1 422.4 236.4 435.6C222.8 441.2 207.8 444.8 192 446.6V480C192 497.7 177.7 512 160 512C142.3 512 128 497.7 128 480V445.1C127.6 445.1 127.1 444.1 126.7 444.9L126.5 444.9C102.2 441.1 62.07 430.6 35 418.6C18.85 411.4 11.58 392.5 18.76 376.3C25.94 360.2 44.85 352.9 60.1 360.1C81.9 369.4 116.3 378.5 136.2 381.6C168.2 386.4 194.5 383.6 212.3 376.4C229.2 369.5 236.9 359.5 239.1 347.5C241 336.8 239.6 330.7 237.8 326.9C235.9 322.9 232.2 318.4 224.9 313.5C208.6 302.8 183.8 295.6 151.6 286.9L148.8 286.1C120.4 278.4 85.58 268.9 59.76 251.8C45.65 242.4 32.43 229.7 24.22 212.1C15.89 194.3 14.08 174.3 17.95 153C25.03 114.1 53.05 89.29 85.96 76.73C98.98 71.76 113.1 68.49 128 66.73V32C128 14.33 142.3 0 160 0V0z" fill="black" />
		</svg>
	);
};
