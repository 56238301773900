//default
import React from "react";
//css
import "./modalCerrarSesion.scss";

const ModalCerrarSesion = ({ params, accionUno, accionDos }) => {
	return (
		<div className="contenedor_modalCerrarSesion">
			(
			<div className="modal">
				<img src={params.icono} className="icono-modal" />
				<p className="parrafo-modalAdvertencias">{params.resultado}</p>
				<div className="linea">
					<button autoFocus onClick={() => accionUno()}>
						Cancelar
					</button>
					<button autoFocus onClick={() => accionDos()}>
						Aceptar
					</button>
				</div>
			</div>
		</div>
	);
};

export default ModalCerrarSesion;
