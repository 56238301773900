//default
import React from "react";
//librerias
import { Routes, Route } from "react-router-dom";
//componentes
import RegistroLineas from "../components/registroLineas";
import ReporteLineas from "../components/reporteLineas";
const Rutas = () => {
	return (
		<div>
			<Routes>
				<Route path="/">
					<Route index element={<RegistroLineas />} />
					<Route path="/registro" element={<RegistroLineas />} />
					<Route path="/reporte" element={<ReporteLineas />} />
				</Route>
			</Routes>
		</div>
	);
};

export default Rutas;
