//default
import React from "react";
//css
import "./modalIncorrecto.scss";

const ModalIncorrecto = ({ params, accion }) => {
	return (
		<div className="contenedor_modal">
			(
			<div className="modal">
				<img src={params.icono} className="icono-modal" />
				<p className="parrafo-modalAdvertencias">{params.resultado}</p>
				<div className="linea">
					<button autoFocus onClick={() => accion()}>
						Aceptar
					</button>
				</div>
			</div>
		</div>
	);
};

export default ModalIncorrecto;
