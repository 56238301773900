import React from 'react'
import "./modalCorrecto.scss";

const ModalCorrecto = (props) => {
    const { limpiarAll, icono, resultado, mostrarModal, setMostrarModal } = props;

    return (
        <div className="modalReset" style={{ display: mostrarModal }}>
            (
            <div className="modal">
                <img src={icono} className="icono-modal" />
                <p className="parrafo-modalAdvertencias">{resultado}</p>
                <div className="linea">
                    <button
                        autoFocus
                        onClick={() => {
                            setMostrarModal("none");
                            limpiarAll();
                        }}
                    >
                        Aceptar
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ModalCorrecto