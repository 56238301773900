//default
import React, { useState, useEffect} from "react";
import { useLocation } from "react-router-dom";
//librerias
import { NavLink } from "react-router-dom";
//icons
import { Img1, Img2, Img3, Img4 } from "../../assets/ico-svg/ico-svg";
// Icono de Advertencia
import advertencia from "../../assets/img/advertencia.png";
//componentes
import ModalCerrarSesion from "../generales/modalCerrarSesion";
//css
import "./navbar.scss";
//URIs
import { saldoActual } from "../../utils/connection/uris.js";
const Navbar = ({ setUser }) => {
	//estado para los params del modal
	const [params, setParams] = useState({});
	//estado para abrir modal de cerrar sesion
	const [abrirModal, setAbrirModal] = useState(false);
	const [saldo, setSaldo] = useState("0")
	useEffect(async () => {
		const response = await fetch(saldoActual)
		const data = await response.json()
		setSaldo(data.saldo);
	}, [])
	//funcion para el active
	function active(active) {
		if (active) {
			return "navbar-item active";
		} else {
			return "navbar-item link";
		}
	}

	//funcion para cerrar sesion
	function handleCerrarSesion() {
		setUser(null);
		//remueve el token del localstorage
		localStorage.removeItem("loggedTypeUser");
	}
	const location = useLocation();

	const cancelar = () => {
		setAbrirModal(false);
	};
	console.log(location.pathname);

	return (
		<div className="navigator">
			{abrirModal ? <ModalCerrarSesion params={params} accionUno={cancelar} accionDos={handleCerrarSesion} /> : null}
			<div>
			<h2>{location.pathname === "/registro" || location.pathname === "/" ? "Registro de Líneas " : "Reporte de Activaciones"}</h2>
			<div className="saldo">
					<Img4 />
					<p>{saldo}</p>
				</div>
			</div>
			<nav>
				<NavLink className={({ isActive }) => active(isActive)} to="/registro">
					<Img1 />
					<p>Registrar</p>
				</NavLink>
				<NavLink className={({ isActive }) => active(isActive)} to="/reporte">
					<Img2 />
					<p>Reportes</p>
				</NavLink>
				<NavLink
					className="navbar-item-salir"
					to="/"
					onClick={() => {
						setParams({ icono: advertencia, resultado: "¿Desea cerrar sesión?" });
						setAbrirModal(true);
					}}
				>
					<Img3 />
					<p>Cerrar Sesión</p>
				</NavLink>
			</nav>
		</div>
	);
};

export default Navbar;
