//Default
import React, { useState, useEffect } from "react";
//libreria
import Litepicker from "litepicker";
//Validaciones
import { validarCadenaNumerica } from "../../utils/lib/validaciones";
import { registroLineas, agregarRegistro, listaLineas } from "../../utils/connection/uris.js";
import ReactExport from "react-export-excel-fixed-xlsx";
//componentes
import Loading from "../../components/generales/loading";
import sinResultados from "../../assets/img/sin-resultados.png";
//css
import "./reporteLineas.scss";
const { format } = require("date-fns");

const ReporteLineas = () => {
	const [fechaIni, setFechaIni] = useState("");
	const [fechaFin, setFechaFin] = useState("");
	const [infoTabla, setInfoTabla] = useState([]);
	const [infoTablaBusqueda, setInfoTablaBusqueda] = useState([]);
	const [busqueda, setBusqueda] = useState("");
	const [consumo, setConsumo] = useState(0);
	const today = format(new Date(), "yyyy-MM-dd");
	let picker;
	const ExcelFile = ReactExport.ExcelFile;
	const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
	const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
	//estado para el modal de cargando
	const [loading, setLoading] = useState(false);
	//estado para deshabilitar el boton de descargar
	const [botonDeshabilitado, setBotonDeshabilitado] = useState(false);

	useEffect(() => {
		if (document.body.contains(document.getElementById("datepicker"))) {
			picker = new Litepicker({
				element: document.getElementById("datepicker"),
				singleMode: false,
				tooltipText: {
					one: "dia",
					other: "dias",
				},
				tooltipNumber: (totalDays) => {
					return totalDays - 1;
				},
				setup: (picker) => {
					picker.on("selected", (date1, date2) => {
						setFechaIni(date1.format("YYYY-MM-DD"));
						setFechaFin(date2.format("YYYY-MM-DD"));
					});
				},
			});
		}
	}, []);

	const onResize = () => {
		if (document.body.contains(document.getElementById("datepicker"))) {
			picker.hide();
		}
	};

	/* Funcion para validar que los campos Numero y Confirmar Numero */
	const llenado = (event) => {
		/* Creamos variable de Numero y Confirmar Numero */
		var numero = document.getElementsByName("numero");
		/* Validamos que despues de 10 digitos, se deje de escribir en Numero */
		if (numero[0].value.length >= 10) {
			numero[0].value = numero[0].value.slice(0, 10);
		}
		/* Validamos que solo sean numeros
        Validamos que sean solo numeros en Campo Numero*/
		numero[0].value = numero[0].value
			.replace(/[-{ºª¢¬·¨çÇ|<(?#’”"¿)>^a-zA-Z!$%&/=`+´.,;:_@∞÷≠‚€œæ®†¥øπå∫∂~§¶™ƒΩ∑©√–…„µß≤ñÑ*}]/g, "")
			.replace("[", "")
			.replace("]", "")
			.replace(" ", "")
			.replace("“", "")
			.replace("”", "")
			.replace("\\", "");
		/* Validamos que sean solo numeros en Campo Numero al Pegar */
		numero[0].onpaste = numero[0].value
			.replace(/[-{ºª¢¬·¨çÇ|<(?#’”"¿)>^a-zA-Z!$%&/=`+´.,;:_@∞÷≠‚€œæ®†¥øπå∫∂~§¶™ƒΩ∑©√–…„µß≤ñÑ*}]/g, "")
			.replace("[", "")
			.replace("]", "")
			.replace(" ", "")
			.replace("“", "")
			.replace("”", "")
			.replace("\\", "");
		/* Se llena el state de Datos, con los datos que se enviaran al API */
		setBusqueda(event.target.value);
	};

	const listarLineas = async () => {
		try {
			setLoading(true);
			let infoLineas = [];
			const response = await fetch(listaLineas + "?cliente_id=687", {
				//Consumo de api
				method: "GET",
				mode: "cors",
				headers: {
					"Content-Type": "application/json",
				},
			});
			const data = await response.json();
			console.log(data);
			if (data.response === 1) {
				let obj = undefined;
				console.log(Date.parse(data["data"][0]["fechaActivado"]));
				data["data"].forEach((element) => {
					obj = {
						numeroLinea: element["numero"],
						fecha: format(Date.parse(element["fechaActivado"]), "yyyy-MM-dd HH:mm:ss"),
					};
					infoLineas.push(obj);
				});
				setLoading(false);
				setConsumo(1);
				return infoLineas;
			}
			if (data.response === 2) {
				let obj = undefined;
				let infoLineas = [];
				obj = {
					numeroLinea: "Sin Resultados",
					fecha: "Sin Resultados",
				};
				infoLineas.push(obj);
				setLoading(false);
				setConsumo(1);
				return infoLineas;
			}
		} catch (error) {
			setLoading(false);
			console.log(error);
			let obj = undefined;
			let infoLineas = [];
			obj = {
				numeroLinea: "Sin Resultados",
				fecha: "Sin Resultados",
			};
			infoLineas.push(obj);
			setLoading(false);
			setConsumo(1);
			return infoLineas;
		}
	};

	useEffect(() => {
		async function fetchData() {
			const date = await listarLineas();
			console.log(date);
			if (date.length > 0 && date[0]["numeroLinea"] !== "Sin Resultados") {
				setInfoTabla(date);
				setBotonDeshabilitado(false);
				console.log("ENTRA AQUI ARRIBA");
				console.log(date);
			} else {
				console.log("ENTRA AQUI");
				setInfoTabla(date);
				setBotonDeshabilitado(true);
			}
		}
		fetchData();
	}, [consumo]);

	useEffect(() => {
		if (infoTabla !== undefined && infoTabla.length > 0 && infoTabla["numeroLinea"] !== "Sin Resultados") {
			var busquedaNumeros = infoTabla.filter(function (numero) {
				return numero.numeroLinea.includes(busqueda);
			});
			setInfoTablaBusqueda(busquedaNumeros);
		}
	}, [busqueda, infoTabla]);

	window.onresize = onResize;

	return (
		<div className="contenedor_reporteLineas">
			{/*LOADING*/}
			{loading ? <Loading /> : null}
			<div className="left">
				<div className="opcionDate" style={{ margin: "0 auto" }}>
					<input type="text" id="datepicker" placeholder={today} disabled />
					<input type="search" name="numero" placeholder="Ingrese número" onChange={(e) => llenado(e)} />
				</div>
				<article>
					<section>
						{busqueda !== "" && infoTablaBusqueda.length === 0 ? (
							<img className="sinResultados" src={sinResultados} />
						) : (
							<table>
								<thead>
									<tr>
										<th>Número línea</th>
										<th>Fecha activación</th>
									</tr>
								</thead>
								<tbody>
									{busqueda !== ""
										? infoTablaBusqueda.map((item, i) => {
												return [
													<tr key={i}>
														<td>{item.numeroLinea}</td>
														<td>{item.fecha}</td>
													</tr>,
												];
										  })
										: infoTabla.map((item, i) => {
												return [
													<tr key={i}>
														<td>{item.numeroLinea}</td>
														<td>{item.fecha}</td>
													</tr>,
												];
										  })}
								</tbody>
							</table>
						)}
					</section>
					{botonDeshabilitado ? (
						<button className="btnDescarga" disabled={true}>
							<span>
								<i className="fa-solid fa-download fa-2x"></i>
							</span>
						</button>
					) : (
						<ExcelFile
							element={
								<button className="btnDescarga">
									<span>
										<i className="fa-solid fa-download fa-2x"></i>
									</span>
								</button>
							}
						>
							<ExcelSheet data={infoTabla} name="Lineas">
								<ExcelColumn label="Numero Linea" value="numeroLinea" />
								<ExcelColumn label="Fecha" value="fecha" />
							</ExcelSheet>
						</ExcelFile>
					)}
				</article>
			</div>
			<div></div>
		</div>
	);
};

export default ReporteLineas;
