//default
import React, { useState } from "react";
//URIs
import { activarLinea } from "../../utils/connection/uris.js";
//validaciones
import { recarga } from "../../utils/lib/validaciones";
//icons
import { Img3 } from "../../assets/ico-svg";
import correcto from "../../assets/img/correcto.png";
import error from "../../assets/img/cerrar.png";
import advertencia from "../../assets/img/advertencia.png";
//componentes
import Modal from "../generales/modal";
import ModalIncorrecto from "../generales/modalIncorrecto";
import ModalCerrarSesion from "../generales/modalCerrarSesion";
import Loading from "../generales/loading";
//css
import "./activarLinea.scss";

const ActivarLinea = ({ setUser }) => {
	//estado para los numeros
	const [numero, setNumero] = useState({ numero: "", confirmarNumero: "" });
	//estado para mostrar el modal
	const [modal, setModal] = useState(false);
	//estado para mostrar el modal Incorrecto
	const [modalIncorrecto, setModalIncorrecto] = useState(false);
	//estado para el modal de cerrar sesion
	const [modalCerrarSesion, setModalCerrarSesion] = useState(false);
	//estado para el loading
	const [loading, setLoading] = useState(false);
	const [params, setParams] = useState({});
	const [paramsIncorrectos, setParamsIncorrectos] = useState({});

	//funcion para activar linea
	async function handleActivarLinea() {
		try {
			setLoading(true);
			const res = await fetch(activarLinea, {
				method: "POST",
				body: JSON.stringify({ numero: numero.numero, tipo: "2", usuario_id: localStorage.getItem("loggedTypeUser") }),
				headers: {
					"Content-Type": "application/json",
				},
			});

			const data = await res.json();

			if (data.Estado === 1) {
				setModal(true);
				setParams({ icono: correcto, resultado: "Petición realizada correctamente" });
				setNumero({ numero: "", confirmarNumero: "" });
				setLoading(false);
			} else {
				console.log("data");
				setModalIncorrecto(true);
				setParamsIncorrectos({ icono: error, resultado: data.Descripcion });
				setLoading(false);
			}
			console.log(data);
		} catch (error) {
			setLoading(false);
		}
	}

	//funcion para llenar los numeros
	function handleNumero(e) {
		setNumero({ ...numero, [e.target.name]: recarga(e.target.value) });
	}

	//validar si los numeros son iguales
	function handleValidar() {
		if (numero.numero.length === 10 || numero.confirmarNumero.length === 10) {
			if (numero.numero === numero.confirmarNumero) {
				handleActivarLinea();
			} else {
				setParamsIncorrectos({ icono: advertencia, resultado: "Numeros diferentes" });
				setModalIncorrecto(true);
				console.log("numeros diferentes");
			}
		} else {
			setParamsIncorrectos({ icono: advertencia, resultado: "La longitud debe de ser 10" });
			setModalIncorrecto(true);
		}
	}

	//cerrar sesion
	function handleCerrarSesion() {
		//remueve el token del localstorage
		localStorage.removeItem("loggedTypeUser");
		setUser(null);
	}

	//funcion que se le pasa el modal cuando es correcto y advertencia
	function handleCliente() {
		setModal(false);
		setModalIncorrecto(false)
	}

	//funcion para limpiar los inputs
	function handleLimpiar() {
		setNumero({ numero: "", confirmarNumero: "" });
	}

	//funcion para abrir el modal de cerrar sesion
	function modalCerrar() {
		setModalCerrarSesion(true);
		setParams({ icono: advertencia, resultado: "¿Estás seguro que quieres cerrar sesión?" });
	}

	//funcion para cerrar el modal de cerrar sesion
	function cerrarModalSesion() {
		setModalCerrarSesion(false);
	}

	return (
		<div className="activarLinea">
			{/*LOADING*/}
			{loading ? <Loading /> : null}
			{/*MODAL*/}
			{modal ? <Modal params={params} accion={handleCliente} /> : null}
			{modalIncorrecto ? <ModalIncorrecto params={paramsIncorrectos} accion={handleCliente} /> : null}
			{/*MODAL CERRAR SESION*/}
			{modalCerrarSesion ? <ModalCerrarSesion params={params} accionUno={cerrarModalSesion} accionDos={handleCerrarSesion} /> : null}
			<div className="contenedor_titulo">
				<h2>Activar Línea</h2>
				<div className="cerrar_sesion" onClick={() => modalCerrar()}>
					<Img3 />
					<p>Cerrar Sesión</p>
				</div>
			</div>
			<p>Número:</p>
			<input onChange={handleNumero} type="tel" name="numero" maxLength="10" value={numero.numero} />
			<p>Confirmar Número:</p>
			<input onChange={handleNumero} type="tel" name="confirmarNumero" maxLength="10" value={numero.confirmarNumero} />
			<div className="contenedor_botones">
				<button onClick={() => handleLimpiar()} className="limpiar">
					Limpiar
				</button>
				<button onClick={() => handleValidar()} className="aceptar">
					Aceptar
				</button>
			</div>
		</div>
	);
};

export default ActivarLinea;
