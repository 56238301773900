const Contants = require("./constants.js");

const { CONEXION_BASE, PORT, PRODUCT_NAME } = Contants;

const admin = "/login/admin";
const cliente = "/login/cliente";
const registrarLineas = "/numero/registrarLineas";
const listarNumeros = "/cliente/numerosregistrados";
const activar = "/recarga/recargarNumero";
const saldo = "/saldo/cantidadSaldoActual";

export const loginAdmin = CONEXION_BASE + PORT + PRODUCT_NAME + admin;
export const loginCliente = CONEXION_BASE + PORT + PRODUCT_NAME + cliente;
export const registroLineas = CONEXION_BASE + PORT + PRODUCT_NAME + registrarLineas;
export const listaLineas = CONEXION_BASE + PORT + PRODUCT_NAME + listarNumeros;
export const activarLinea = CONEXION_BASE + PORT + PRODUCT_NAME + activar;
export const saldoActual = CONEXION_BASE + PORT + PRODUCT_NAME + saldo;
/**
 * LOGS
 */

export let agregarRegistro = "https://pruebasmorpheus.com:17200/agregarRegistro";