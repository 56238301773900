//default
import React from "react";
//css
import "./tablaLineasRegistradas.scss";

const TablaLineasRegistradas = ({ lineasRechazadas, setMostrarTablaLineas }) => {
	return (
		<div className="contenedor_tablaLineas">
			<div className="contenedor_todo">
				<h2>Lineas Rechazadas</h2>
				<div className="tabla_lineas">
					<table>
						<thead>
							<tr>
								<th>Número</th>
								<th>Iccid</th>
								<th>Descripción</th>
							</tr>
						</thead>
						<tbody>
							{lineasRechazadas.map((item, key) => {
								return (
									<tr key={key}>
										<td>{item.numero}</td>
										<td>{item.iccid}</td>
										<td>{item.descripcion}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
				<button onClick={() => setMostrarTablaLineas(false)}>Cerrar</button>
			</div>
		</div>
	);
};

export default TablaLineasRegistradas;
