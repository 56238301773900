import React from 'react';
import "./footer.scss";

const Footer = ()=>{


    return(
        <footer>
            
            <p className='p1'>Ayuda y soporte técnico 45552312323</p>
           
            
            <p className='p2'>Todos los derechos reservados Morpheus DSS</p>
           
        </footer>
    )
}
export default Footer;
