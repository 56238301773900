/**
 * Función que da formato mayúsculo a la primer letra de una palabra
 * @param {String} cadenaEvaluar Recibe la cadena que se desea formatear
 */
export let cambiarPrimerLetraMayuscula = (cadenaEvaluar) => {
	return cadenaEvaluar.substr(0, 1).toUpperCase() + cadenaEvaluar.substr(1, cadenaEvaluar.length).toLowerCase();
};

/**
 * Función que da formato Capital a una cadena
 * @param {String} valorEvaluado Recibe la cadena que se desea formatear
 */
export let capitalizarCadena = (valorEvaluado) => {
	valorEvaluado = valorEvaluado.trimStart();
	valorEvaluado = eliminarCaracteresInvalidos(valorEvaluado);
	valorEvaluado = reducirEspaciosBlanco(valorEvaluado);
	let palabras = valorEvaluado.split(" ");
	let cadenaCapitalizada = "";
	let cantidadPalabras = palabras.length;
	for (let i = 0; i < cantidadPalabras; i++) {
		if (i !== cantidadPalabras - 1) {
			cadenaCapitalizada = cadenaCapitalizada + cambiarPrimerLetraMayuscula(palabras[i]) + " ";
		} else {
			cadenaCapitalizada = cadenaCapitalizada + cambiarPrimerLetraMayuscula(palabras[i]);
		}
	}
	return cadenaCapitalizada;
};

/**
 * Función que da formato Capital a un nombre propio
 * @param {String} valorEvaluado Recibe la cadena que se desea formatear
 */
export let capitalizarNombre = (valorEvaluado) => {
	valorEvaluado = valorEvaluado.toLocaleLowerCase();
	valorEvaluado = valorEvaluado.trimStart();
	valorEvaluado = valorEvaluado.replace(/[^A-Za-zñÑÁáÉéÍíÓóÚú´. ]/g, "");

	valorEvaluado = reducirEspaciosBlanco(valorEvaluado);
	let palabras = valorEvaluado.split(" ");
	let cadenaCapitalizada = "";
	let cantidadPalabras = palabras.length;
	for (let i = 0; i < cantidadPalabras; i++) {
		if (i !== cantidadPalabras - 1) {
			cadenaCapitalizada = cadenaCapitalizada + cambiarPrimerLetraMayuscula(palabras[i]) + " ";
		} else {
			cadenaCapitalizada = cadenaCapitalizada + cambiarPrimerLetraMayuscula(palabras[i]);
		}
	}
	return cadenaCapitalizada;
};

/**
 * Método que verifica si dos JSON son iguales
 * @param {JSON} primerArray Recibe el primer arreglo de tipo JSON que se desea evaluar
 * @param {JSON} segundoArray Recibe el segundo arreglo de tipo JSON que se desea evaluar
 */
export let compararArreglos = (primerArray, segundoArray) => {
	return JSON.stringify(primerArray) === JSON.stringify(segundoArray);
};

/**
 * Función que elimina los carácteres que no son permitidos en el sistema
 * @param {String} cadenaEvaluar Recibe la cadena que se desea formatear
 */
export let eliminarCaracteresInvalidos = (cadenaEvaluar) => {
	return cadenaEvaluar.replace(/[&\\#()$~'":*?<>{}^[\]=]/g, "");
};

/**
 * Función que elimina los espacios a una cadena
 * @param {String} cadenaEvaluar Recibe la cadena que se desea formatear
 */
export let eliminarEspaciosBlanco = (cadenaEvaluar) => {
	return cadenaEvaluar.replace(/\s/g, "");
};

/**
 * Función que solo permite sean ingresados números a un campos
 * @param {String} valorEvaluado Recibe la cadena que se desea validar
 */
export let permitirSoloNumeros = (valorEvaluado) => {
	return valorEvaluado.replace(/[^0-9]/g, "");
};

/**
 * Función que evita se ingresen espacios dobles
 * @param {String} cadenaEvaluar Recibe la cadena que se desea formatear
 */
export let reducirEspaciosBlanco = (cadenaEvaluar) => {
	return cadenaEvaluar.replace(/\s+/g, " ");
};

/**
 * Función que separa un folio en sus 3 elementos (regioón, tipo de chip y número consecutivo)
 * @param {String} folioEvaluado Recibe el folio que se desea separar
 */
export let separarFolio = (folioEvaluado) => {
	let folioSeparado = folioEvaluado.split("-");
	return {
		parteInicial: folioSeparado[0],
		parteNumerica: folioSeparado[1],
	};
};

/**
 * Función que evita sean introducidos información invalida y permite espacios
 * @param {String} valorEvaluado Recibe la cadena que se desea evaluar
 */
export let validarCadenaConEspacio = (valorEvaluado) => {
	valorEvaluado = valorEvaluado.trimStart();
	valorEvaluado = eliminarCaracteresInvalidos(valorEvaluado);
	valorEvaluado = reducirEspaciosBlanco(valorEvaluado);
	return valorEvaluado;
};

/**
 * Función que valida que una cadena sea de tipo numérico y tenga el tamaño deseado
 * @param {String} cadenaEvaluar Recibe la cadena que se desea evaluar
 * @param {int} longuitudMinima Recibe el tamaño mínimo de la cadena
 * @param {int} longuitudMaxima Recibe el tamaño máximo del elemento
 */
export let validarCadenaNumerica = (cadenaEvaluar, longuitudMinima, longuitudMaxima) => {
	let digitos = /^[0-9]+$/;
	if (cadenaEvaluar.length >= longuitudMinima && cadenaEvaluar.length <= longuitudMaxima) {
		if (cadenaEvaluar.match(digitos)) {
			return true;
		} else {
			return false;
		}
	} else {
		return false;
	}
};
export let validarCadenaNumericaLetra = (cadenaEvaluar, longuitudMinima, longuitudMaxima) => {
	let digitos = /((([0-9]{18}))|([0-9]{19}))/;
	if (cadenaEvaluar.length >= longuitudMinima && cadenaEvaluar.length <= longuitudMaxima) {
		if (cadenaEvaluar.match(digitos)) {
			return true;
		} else {
			return false;
		}
	} else {
		return false;
	}
};

let numeroCaracteres = (value, tamano) => {
	return value.substring(0, tamano);
};

/**
 * Función que verifica si un campo contiene las dimensiones requeridas
 * @param {Object} variableEvaluada Recibe el elemento que se desea verificar
 * @param {int} longuitudMinima Recibe el tamaño mínimo del elemento
 * @param {int} longuitudMaxima Recibe el tamaño máximo del elemento
 */
export let validarDimensionValor = (variableEvaluada, longuitudMinima, longuitudMaxima) => {
	if (variableEvaluada === undefined) {
		return false;
	} else {
		if (variableEvaluada.length > 0) {
			if (variableEvaluada.length >= longuitudMinima && variableEvaluada.length <= longuitudMaxima) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}
};

/**
 * Función que evita sean introducidos guiones iniciales y mas de un guión
 * @param {String} cadenaEvaluar Recibe la cadena que se desea evaluar
 */
export let validarGuionUnico = (cadenaEvaluar) => {
	cadenaEvaluar = validarCadena(cadenaEvaluar);
	if (cadenaEvaluar.length > 0) {
		if (cadenaEvaluar.substring(0, 1) === "-") {
			return cadenaEvaluar.replace("-", "");
		} else {
			var palabras = cadenaEvaluar.split("-");
			if (palabras.length > 1) {
				let cadenaNueva = "";
				for (var i = 0; i < palabras.length; i++) {
					cadenaNueva += palabras[i];
					if (i === 0) {
						cadenaNueva += "-";
					}
				}
				return cadenaNueva.toUpperCase();
			} else {
				return cadenaEvaluar.toUpperCase();
			}
		}
	} else {
		return "";
	}
};

/**
 * Función que verifica si el folio proporcionado tiene el formato adecuado
 * @param {String} folioEvaluado Recibe la cadena que se desea evaluar
 */
export let validarRegistroFolio = (folioEvaluado) => {
	let folioSeparado = folioEvaluado.split("-");
	if (folioSeparado.length > 1) {
		if (folioSeparado[0] !== "" && folioSeparado[1] !== "") {
			try {
				//let tipoChipParteNumerica = folioSeparado[1].substring(0,5)
				// let tipoChipLetraConsecutiva = folioSeparado[1].substring(0,5).toUpperCase()
				let digitos = /^[0-9]+$/;
				let letras = /^[A-Z]+$/i;
				if (folioSeparado[0].length <= 5) {
					// let parteNumerica = folioSeparado[1].substring(5,folioSeparado[0].length)
					if (folioSeparado[1].match(digitos)) {
						console.log("q");
						return true;
					} else {
						console.log("x");
						return false;
					}
				} else {
					console.log("y");
					return false;
				}
			} catch {
				console.log("z");
				return false;
			}
		}
	} else {
		return false;
	}
};

//Caracteres no permitidos
let caracteresNoPermitidos = (value) => {
	return value
		.replace(/[{{}()<>^"?=:*&#]/g, "")
		.replace("[", "")
		.replace("]", "");
};

/**
 * Método que verifica si un campo numérico tiene las dimensiones requeridas
 * @param {Object} valorEvaluado Recibe el elemento que se desea verificar
 * @param {int} valorMinimo Recibe el tamaño mínimo del elemento
 * @param {int} valorMaximo Recibe el tamaño máximo del elemento
 */
export let validarValorNumerico = (valorEvaluado, valorMinimo, valorMaximo) => {
	if (valorEvaluado >= valorMinimo && valorEvaluado <= valorMaximo) {
		return true;
	} else {
		return false;
	}
};

export let recarga = (valorEvaluado) => {
	valorEvaluado = permitirSoloNumeros(valorEvaluado);
	valorEvaluado = reducirEspaciosBlanco(valorEvaluado);
	return valorEvaluado;
};

export let numeroIgual = (valorEvaluado) => {
	if (valorEvaluado.numero !== valorEvaluado.confirmarNumero) {
		return false;
	} else {
		return true;
	}
};

/**
 * Función que evita sean introducidos información invalida
 * @param {String} valorEvaluado Recibe la cadena que se desea evaluar
 */
export let validarCadena = (valorEvaluado) => {
	valorEvaluado = eliminarCaracteresInvalidos(valorEvaluado);
	valorEvaluado = eliminarEspaciosBlanco(valorEvaluado);
	return valorEvaluado;
};

//validacion para el usuario y el pass del login
export let validarLogin = (valorEvaluado, tamano) => {
	valorEvaluado = eliminarEspaciosBlanco(valorEvaluado);
	valorEvaluado = numeroCaracteres(valorEvaluado, tamano);
	valorEvaluado = caracteresNoPermitidos(valorEvaluado);
	return valorEvaluado.trim();
};
