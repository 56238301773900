//default
import React, { useState, useEffect } from "react";
//css
import "./App.css";
//imagenes
import Fondo from "../src/assets/img/Fondo.png";
//componentes
import Login from "./components/login";
import Footer from "./components/generales/footer/footer";
import Principal from "./pages/principal";
import ActivarLinea from "./components/activarLinea";

function App() {
	//estado que contiene la información del usuario que desea logearse
	const [user, setUser] = useState(null);

	//efecto para mantener la sesion activa
	useEffect(() => {
		const loggedTypeUser = localStorage.getItem("loggedTypeUser");
		if (loggedTypeUser) {
			const tipoUsuario = JSON.parse(loggedTypeUser);
			setUser(tipoUsuario);
		}
	}, []);

	return (
		<div className="principal" style={{ backgroundImage: `url(${Fondo})` }}>
			{user === null ? <Login setUser={setUser} /> : user === "administrador" ? <Principal setUser={setUser} /> : <ActivarLinea setUser={setUser} />}
			<Footer />
		</div>
	);
}

export default App;
