//default
import React from "react";
//css
import "./loading.scss";

const Loading = () => {
	return (
		<div className="loading">
			<p>CARGANDO...</p>
		</div>
	);
};

export default Loading;
