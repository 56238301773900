//default
import React, { useState, useEffect } from "react";
//uris
import { loginAdmin, loginCliente } from "../../utils/connection/uris.js";
//Validaciones
import { validarLogin } from "../../utils/lib/validaciones";
//componentes
import Loading from "../generales/loading";
import Modal from "../generales/modal";
import ModalIncorrecto from "../generales/modalIncorrecto";
import correcto from "../../assets/img/correcto.png";
import error from "../../assets/img/cerrar.png";
//imaganes
import eye from "../../assets/img/eye.png";
import blind from "../../assets/img/blind.png";
//css
import "./login.scss";

const Login = ({ setUser }) => {
	//estado que contiene la información del usuario que desea logearse
	const [userLogin, setUserLogin] = useState({ pass: "", usuario: "" });
	//estado para mostrar el modal
	const [modal, setModal] = useState(false);
	const [modalIncorrecto, setModalIncorrecto] = useState(false);
	//estado para mostrar el modal de inputs vacios
	const [modalInput, setModalInput] = useState(false);
	//estado para el loading
	const [loading, setLoading] = useState(false);
	//estado para mostrar el password
	const [showPass, setShowPass] = useState(true);
	//estado para los params del modal
	const [params, setParams] = useState({});
	const [paramsIncorrectos, setParamsIncorrectos] = useState({});
	const [datosUser, setDatosUser] = useState({});
	const [cliente, setCliente] = useState(false);
	const [admin, setAdmin] = useState(false);
	//estado para bloquear el boton de login
	const [disabledLogin, setDisabledLogin] = useState(true);

	/**
	 * Método que obtiene la información que proporciona el usuario
	 * @param {event} event Evento lanzado al ingresar información
	 */
	const handleInputChange = (event) => {
		const { name, value } = event.target;

		setUserLogin({
			...userLogin,
			[name]: validarLogin(value, 20),
		});
	};

	/**
	 * Función que verifica si el usuario tiene permitido acceder al sistema
	 * @param {String} nickEncriptado Recibe el nick de acceso encriptado
	 * @param {String} passEncriptado Recibe el pass de acceso encriptado
	 */
	const handleLoginAdmin = async () => {
		console.log("entra");
		try {
			//consumo del api
			const response = await fetch(loginAdmin, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(userLogin),
			});
			const data = await response.json();
			console.log(data);
			if (data.response === 0) {
				console.log("no existe usuario");
				return false;
			} else {
				if (data.tipo === "Super Administrador" || data.tipo === "Administrador") {
					setDatosUser({ data: data, user: "administrador" });
					//setUser("administrador");
					//guardar el token en el localstorage
					//localStorage.setItem("loggedTypeUser", JSON.stringify("administrador"));
					return true;
				}
			}
		} catch (error) {
			console.log(error);
			//error
			return false;
		}
	};

	/**
	 * Función que verifica si el usuario tiene permitido acceder al sistema
	 * @param {String} nickEncriptado Recibe el nick de acceso encriptado
	 * @param {String} passEncriptado Recibe el pass de acceso encriptado
	 */
	const handleLoginCliente = async () => {
		console.log("entra");
		try {
			//consumo del api
			const response = await fetch(loginCliente, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(userLogin),
			});

			const data = await response.json();
			console.log(data);
			if (data.response === 0) {
				console.log("no existe usuario");
				return { valor: false };
			} else {
				console.log(data);
				setDatosUser({ data: data, user: "cliente" });
				//guardar el token en el localstorage
				//localStorage.setItem("loggedTypeUser", JSON.stringify(data.cliente_id));
				//setUser("cliente");
				return { data: data, user: "cliente", valor: true };
			}
		} catch (error) {
			//error
			console.log(error);
			return { valor: false };
		}
	};

	//funcion para loguear
	async function handleLogueo(e) {
		e.preventDefault();
		console.log(userLogin);
		setLoading(true);
		const resCliente = await handleLoginCliente();
		console.log(resCliente);
		if (resCliente.valor) {
			//modal inicio de sesion de cliente correcto
			setModal(true);
			setParams({ icono: correcto, resultado: "Petición realizada correctamente" });
			//handleCliente(resCliente);
			setLoading(false);
			setCliente(true);
		} else {
			const resAdmin = await handleLoginAdmin();
			console.log(resAdmin);
			if (resAdmin) {
				//modal inicio de sesion de admin correcto
				setLoading(false);
				setModal(true);
				setAdmin(true);
				setParams({ icono: correcto, resultado: "Petición realizada correctamente" });
			} else {
				//credenciales incorrectas
				setLoading(false);
				setParamsIncorrectos({ icono: error, resultado: "Usuario no encontrado" });
				setModalIncorrecto(true);
			}
		}
	}

	//cliente correcto
	function handleCliente() {
		if (cliente) {
			localStorage.setItem("loggedTypeUser", JSON.stringify(datosUser.data.cliente_id));
			setUser(datosUser.user);
		} else if (admin) {
			setUser(datosUser.user);
			localStorage.setItem("loggedTypeUser", JSON.stringify(datosUser.user));
		} else {
			setModal(false);
			setModalIncorrecto(false)
		}
	}

	//funcion para quitar el modal de inputs vacios
	function handleInputs() {
		setModalInput(false);

	}

	//efecto para la validacion de los inputs
	useEffect(() => {
		console.log(userLogin);
		if (userLogin.usuario === "" || userLogin.pass === "") {
			setDisabledLogin(true);
		} else {
			setDisabledLogin(false);
		}
	}, [userLogin]);

	return (
		<div className="contenedor_login">
			{/*LOADING*/}
			{loading ? <Loading /> : null}
			{/*MODAL*/}
			{modal ? <Modal params={params} accion={handleCliente} /> : null}
			{modalIncorrecto ? <ModalIncorrecto params={paramsIncorrectos} accion={handleCliente} /> : null}
			{/*MODAL DATOS VACIOS*/}
			{modalInput ? <Modal params={params} accion={handleInputs} /> : null}
			<form onSubmit={(e) => handleLogueo(e)} autoComplete="off" method="post">
				<h2>Iniciar Sesion</h2>
				<p>Usuario:</p>
				<input type="text" name="usuario" maxLength="20" onChange={handleInputChange} value={userLogin.usuario} />
				<p>Contaseña:</p>
				<div className="contenedor_pass">
					<input type={showPass ? "password" : "text"} name="pass" maxLength="20" onChange={handleInputChange} value={userLogin.pass} />
					{showPass ? (
						<img alt="img blind" src={blind} onClick={() => setShowPass(!showPass)} />
					) : (
						<img alt="img eye" src={eye} onClick={() => setShowPass(!showPass)} />
					)}
				</div>
				<div className="contenedor_botones">
					<button disabled={disabledLogin} className="aceptar" type="submit">
						Entrar
					</button>
				</div>
			</form>
		</div>
	);
};

export default Login;
