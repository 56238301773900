import React, { useState, useEffect } from "react";
import "./registroLineas.scss";
//Validaciones
import { validarCadenaNumerica } from "../../utils/lib/validaciones";
import { registroLineas, agregarRegistro } from "../../utils/connection/uris.js";
import imgDrag from "../../assets/img/seleccionar-archivo.png";
import ModalCorrecto from "../modalCorrecto/modalCorrecto";
import correcto from "../../assets/img/correcto.png";
import cargados from "../../assets/img/cargados.png";
import advertencia from "../../assets/img/advertencia.png";
import Modal from "../../components/generales/modal";
import ModalIncorrecto from "../generales/modalIncorrecto";
import Loading from "../generales/loading";
import TablaLineasRegistradas from "../tablaLineasRegistradas";
import soltar from "../../assets/img/soltar_archivo.png";

const RegistroLineas = () => {
	//estado que almacena el archivo con el lote de lineas
	const [contenidoArchivo, setContenidoArchivo] = useState();

	//estado que almacena las lineas para registro
	const [lineasParaRegistro, setLineasParaRegistro] = useState([]);
	//estado que contiene el conenido del archivo separado
	const [loteLineas, setLoteLineas] = useState([]);
	//estado para mostrar la pantalla de carga
	const [cargando, setCargando] = useState(false);
	//estado para mostrar Modal
	const [mostrarReset, setMostrarReset] = useState("none");
	//estado del icono
	const [icono, setIcono] = useState(null);
	//estado del mensaje del modal
	const [mensajeUsuario, setMensajeUsuario] = useState("");
	//estado para on hoverZone
	const [onHoverZone, setOnHoverZone] = useState(false);
	//Estado para los archivos cargados
	const [cargadaZona, setCargadaZona] = useState(false);
	//estado para mostrar el modal
	const [modal, setModal] = useState(false);
	//estado para mostrar el modal Incorrecto
	const [modalIncorrecto, setModalIncorrecto] = useState(false);
	//estado para los params del modal
	const [params, setParams] = useState({});
	const [paramsIncorrectos, setParamsIncorrectos] = useState({});
	//estado para mostrar la tabla de registro lineas
	const [mostrarTablaLineas, setMostrarTablaLineas] = useState(false);
	const [lineasRechazadas, setLineasRechazadas] = useState([]);

	function onChange(event) {
		let archivo = event.target.files[0];

		if (archivo) {
			let reader = new FileReader();

			reader.onload = function (e) {
				let contenido = e.target.result;
				setCargadaZona(true);
				setContenidoArchivo(contenido);
			};
			reader.readAsText(archivo);
		} else {
			console.log("No ha seleccionado ningún archivo de líneas");
		}
	}

	//funcion del drag and drop
	function dropHandler(ev) {
		// Evitar el comportamiendo por defecto (Evitar que el fichero se abra/ejecute)
		ev.preventDefault();
		//validacion si hay mas de dos archivos
		if (ev.dataTransfer.items.length === 1) {
			//validacion si el archivo va vacio
			if (ev.dataTransfer.items[0].getAsFile().size !== 0) {
				// Usar la interfaz DataTransferItemList para acceder a el/los archivos)
				for (var i = 0; i < ev.dataTransfer.items.length; i++) {
					var archivo = ev.dataTransfer.items[i].getAsFile();
					if (archivo.type === "text/plain") {
						let reader = new FileReader();

						reader.onload = function (e) {
							let contenido = e.target.result;
							setContenidoArchivo(contenido);
						};
						reader.readAsText(archivo);
						setCargadaZona(true);
					} else {
						limpiarAll();
						setParamsIncorrectos({ icono: advertencia, resultado: "Solo se permiten archivos de texto" });
						setModalIncorrecto(true);
					}
				}
			} else {
				limpiarAll();
				setParamsIncorrectos({ icono: advertencia, resultado: "El archvo no contiene información" });
				setModalIncorrecto(true);
			}
		} else {
			limpiarAll();
			setParamsIncorrectos({ icono: advertencia, resultado: "Solo se permite un archivo" });
			setModalIncorrecto(true);
		}

		// Pasar el evento a removeDragData para limpiar
		removeDragData(ev);
	}

	function dragOverHandler(ev) {
		console.log("File(s) in drop zone");
		setOnHoverZone(true);
		//setInZone(true);
		// Prevent default behavior (Prevent file from being opened)
		ev.preventDefault();
	}

	function removeDragData(ev) {
		console.log("Removing drag data");

		if (ev.dataTransfer.items) {
			// Use DataTransferItemList interface to remove the drag data
			ev.dataTransfer.items.clear();
		} else {
			// Use DataTransfer interface to remove the drag data
			ev.dataTransfer.clearData();
		}
	}

	function dragLeaveHandler(ev) {
		// Prevent default behavior (Prevent file from being opened)
		ev.preventDefault();
		setOnHoverZone(false);
		console.log("Fuera del Drag");
		//setInZone(false);
	}

	//funcion para limpiar todo
	function limpiarAll() {
		setContenidoArchivo();
		setLineasParaRegistro([]);
		setLoteLineas([]);
		setOnHoverZone(false);
		setCargadaZona(false);
	}

	/**
	 * Función que registra líneas
	 */
	const handleBtnRegistrar = () => {
		solicitarRegistroLineas();
	};

	/**
	 * Función que solicita el registro de un lote de líneas mediante API
	 */
	const solicitarRegistroLineas = async () => {
		if (lineasParaRegistro.length !== 0) {
			let lineasArray = [];
			for (var i = 0; i < lineasParaRegistro.length; i++) {
				let lineasObject = {
					numero: lineasParaRegistro[i].numero,
					iccid: lineasParaRegistro[i].iccid,
					folio: lineasParaRegistro[i].iccid,
					imei: lineasParaRegistro[i].imei,
				};
				lineasArray.push(lineasObject);
			}
			let param = {
				param: {
					cliente_id: 688,
					monto: 50,
					carrier_id: 9,
					lineas: lineasArray,
				},
			};
			console.log(param);
			console.log(lineasArray);

			try {
				setCargando(true);
				const response = await fetch(registroLineas, {
					method: "POST",
					mode: "cors",
					body: JSON.stringify(param),
					headers: {
						"Content-Type": "application/json",
					},
				});

				const responseJson = await response.json();
				console.log(responseJson);
				if (responseJson.response === 1) {
					if (responseJson.data === 1) {
						setCargando(false);
						setParams({ icono: correcto, resultado: responseJson.message });
						setModal(true);
						limpiarAll()
					} else {
						let rechazadosArray = [];
						if (responseJson.data.detalles.length > 0) {
							for (i = 0; i < responseJson.data.detalles.length; i++) {
								let numeroObject = {
									folio: responseJson.data.detalles[i].numeros.folio,
									numero: responseJson.data.detalles[i].numeros.numero,
									iccid: responseJson.data.detalles[i].numeros.iccid,
									descripcion: responseJson.data.detalles[i].numeros.detalle,
								};
								rechazadosArray.push(numeroObject);
							}
							console.log(rechazadosArray);
							setLineasRechazadas(rechazadosArray);
							setMostrarTablaLineas(true);
						} else {
							setParams({ icono: correcto, resultado: responseJson.message });
							setModal(true);
							limpiarAll()
						}
						setCargando(false);
					}
					limpiarAll();
				} else {
					let rechazadosArray = [];
					if (responseJson.data.detalles.length > 0) {
						for (i = 0; i < responseJson.data.detalles.length; i++) {
							let numeroObject = {
								folio: responseJson.data.detalles[i].numeros.folio,
								numero: responseJson.data.detalles[i].numeros.numero,
								iccid: responseJson.data.detalles[i].numeros.iccid,
								descripcion: responseJson.data.detalles[i].numeros.detalle,
							};
							rechazadosArray.push(numeroObject);
						}
						console.log(rechazadosArray);
						setLineasRechazadas(rechazadosArray);
						setMostrarTablaLineas(true);
					}
					setCargando(false);
					limpiarAll();
				}
			} catch (error) {
				setCargando(false);
				setParamsIncorrectos({ icono: advertencia, resultado: "Error en el servidor" });
				setModalIncorrecto(true);
				limpiarAll();
			}
		} else {
			setParamsIncorrectos({ icono: advertencia, resultado: "No se han cargado lineas" });
			setModalIncorrecto(true);
			limpiarAll();
		}
	};

	useEffect(() => {
		if (contenidoArchivo !== undefined) {
			var contenidoLineasArray = contenidoArchivo.split(/\r?\n/).map((linea) => {
				return linea.split("\t");
			});
			setLoteLineas(contenidoLineasArray);
		}
	}, [contenidoArchivo]);

	useEffect(() => {
		let numerosArray = [];
		console.log(loteLineas);
		for (var i = 0; i < loteLineas.length; i++) {
			console.log(loteLineas[i].length);
			// let Aumentar = true
			let numero = "";
			let iccid = "";
			// let imei = ""
			//var str = "" + parteNumerica
			//Verificar si se ingresa imei
			if (loteLineas[i].length === 2) {
				numero = loteLineas[i][0].trim();
				iccid = loteLineas[i][1].trim();
				//Validar el tamaño del número y del iccid
				if (validarCadenaNumerica(numero, 10, 10)) {
					if (validarCadenaNumerica(iccid, 18, 19)) {
						iccid = iccid.substr(0, 18);
						console.log(iccid);
						// En caso de que se quite la validaciones del ICCID, borra esta seccion
						if (iccid.substring(0, 5) !== "89520") {
							limpiarAll();
							setParamsIncorrectos({ icono: advertencia, resultado: "Formato del archivo invalido" });
							setModalIncorrecto(true);
							break;
						} else {
							console.log("no toma la validacion");
							// Hasta Aqui
							let numeroObject = {
								// "folio": folioFinal,
								numero: numero,
								iccid: iccid,
								imei: "0",
							};
							numerosArray.push(numeroObject);
						}
					} else {
						limpiarAll();
						setParamsIncorrectos({ icono: advertencia, resultado: "Formato del archivo invalido" });
						setModalIncorrecto(true);
						break;
					}
				} else {
					limpiarAll();
					setParamsIncorrectos({ icono: advertencia, resultado: "Formato del archivo invalido" });
					setModalIncorrecto(true);
					break;
				}
			} else {
				limpiarAll();
				setParamsIncorrectos({ icono: advertencia, resultado: "Formato del archivo invalido" });
				setModalIncorrecto(true);
				break;
			}
		}

		setLineasParaRegistro(numerosArray);
	}, [loteLineas]);

	//funcion que se le pasa el modal cuando es correcto y advertencia
	function handleCliente() {
		setModal(false);
		setModalIncorrecto(false);
	}

	return (
		<div className="contenedor_registroLineas">
			{/* Modal */}
			{mostrarReset === "flex" ? (
				<ModalCorrecto
					limpiarAll={limpiarAll}
					icono={icono}
					resultado={mensajeUsuario}
					mostrarModal={mostrarReset}
					setMostrarModal={setMostrarReset}
				/>
			) : null}
			{/*MODAL*/}
			{modal ? <Modal params={params} accion={handleCliente} /> : null}
			{modalIncorrecto ? <ModalIncorrecto params={paramsIncorrectos} accion={handleCliente} /> : null}
			{/*TABLA LINEAS*/}
			{mostrarTablaLineas ? <TablaLineasRegistradas lineasRechazadas={lineasRechazadas} setMostrarTablaLineas={setMostrarTablaLineas} /> : null}
			{/*LOADING*/}
			{cargando ? <Loading /> : null}
			<p>Para registrar tus líneas, selecciona o arrastra un archivo .txt con los números y códigos ICCID</p>
			{cargadaZona ? (
				<div
					style={{ backgroundImage: `url(${cargados})` }}
					className="drop"
					onDrop={(event) => {
						dropHandler(event);
					}}
					onDragOver={(event) => {
						dragOverHandler(event);
					}}
					onDragLeave={(event) => {
						dragLeaveHandler(event);
					}}
				></div>
			) : onHoverZone ? (
				<div
					style={{ backgroundImage: `url(${soltar})` }}
					className="drop"
					onDrop={(event) => {
						dropHandler(event);
					}}
					onDragOver={(event) => {
						dragOverHandler(event);
					}}
					onDragLeave={(event) => {
						dragLeaveHandler(event);
					}}
				></div>
			) : (
				<div
					className="drag"
					onDrop={(event) => {
						dropHandler(event);
					}}
					onDragOver={(event) => {
						dragOverHandler(event);
					}}
					onDragLeave={(event) => {
						dragLeaveHandler(event);
					}}
				>
					<input
						type="file"
						onChange={(event) => {
							onChange(event);
						}}
						style={{ opacity: "0.0", position: "relative", top: "0", left: "0", bottom: "0", right: "0", width: "100%", height: "100%" }}
						accept=".txt"
					/>
				</div>
			)}
			<div className="contenedor_botones">
				<button className="limpiar" onClick={() => limpiarAll()}>
					Cancelar
				</button>
				<button className="aceptar" onClick={handleBtnRegistrar}>
					Registrar
				</button>
			</div>
		</div>
	);
};

export default RegistroLineas;
